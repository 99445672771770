import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import "./BuyGigatalik.css";
import ethereumBaseSolanaIllustration from "../../Assets/images/ethereum-base-solana.svg";
import etherScanLogo from "../../Assets/images/etherscan_logo.png.png";
import dexScreenerLogo from "../../Assets/images/dex_screener_logo.png.png"
import cmcLogo from "../../Assets/images/cmc-logo.png";
import "@uniswap/widgets/fonts.css";
const BuyGigatalik = () => {
  const API_KEY = "9169653a841b4ff0b83cb28ac6319f1c";
  const jsonRPCEndpoint = `https://mainnet.infura.io/v3/${API_KEY}`;
  const jsonRpcProvider = new ethers.providers.JsonRpcProvider(jsonRPCEndpoint);
  const [account, setAccount] = useState({
    address: "",
    provider: jsonRpcProvider,
  });
  const theme: Theme = {
    primary: "#1F4A05",
    secondary: "#5F7D52",
    interactive: "#CBD6BA",
    container: "#D9ECD9",
    module: "#E9F7DF",
    accent: "#8E8B78",
    outline: "#4cff27",
    dialog: "#FFF",
    fontFamily: "Palanquin Dark",
  };

  useEffect(() => {
    async function connectWallet() {
      const ethereumProvider = await detectEthereumProvider();
      if (ethereumProvider && ethereumProvider.request) {
        await ethereumProvider.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(ethereumProvider);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setAccount({
          address: address,
          provider: provider,
        });
      } else {
        console.error("Please install MetaMask!");
      }
    }
    //connectWallet();
  }, []);
  return (
    <div className="buy-container">
      <div className="multi-chain">
        <div className="multi-chain-text">
          <h4>
            <span>#GigaTALIK</span> is created <br />
            on Multichain Networks
          </h4>
        </div>
        <div className="multi-chain-illustration">
          <img src={ethereumBaseSolanaIllustration} alt="" />
        </div>
      </div>

      <div className="buy-details">
        <div className="Uniswap">
          <iframe
            src="https://app.uniswap.org/swap?chain=bnb"
            title="Uniswap Widget"
          ></iframe>
        </div>

        <div className="buy-now-details">
          <h3>BUY GIGATALIK</h3>
          <p>
            LEGAL DISCLAIMER: $TALIK is a crypto coin with no intrinsic value or
            expectation of financial return. Just because some people are
            getting ridiculously rich buying crypto doesn’t mean you definitely
            will. MOG is to be used strictly for getting laid and for
            entertainment purposes only.
          </p>
          <div className="buy-illustrations">
            <img src={cmcLogo} alt="CoinmarketCap Logo" />
            <img src={dexScreenerLogo} alt="Dex Screener Logo" />
            <img src={etherScanLogo} alt="Ether Scan Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyGigatalik;
